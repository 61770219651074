import { HashLink } from "../../../../src/components/link";
import { Table } from "../../../../src/components/table";
import typeFixesGraph from "../../../../src/images/type-fixes-over-time.png";
import totalTypeErrorsImg from "../../../../src/images/total-type-errors.png";
import * as React from 'react';
export default {
  HashLink,
  Table,
  typeFixesGraph,
  totalTypeErrorsImg,
  React
};