import { HashLink, InternalLink } from "../../../../src/components/link";
import asyncFollowerImg from "../../../../src/images/designing-data-intensive-apps/async-follower.png";
import bTreeImg from "../../../../src/images/designing-data-intensive-apps/b-tree-diagram.png";
import consistentPrefixImg from "../../../../src/images/designing-data-intensive-apps/bug-without-consistent-prefix-reads.png";
import monotonicReadsImg from "../../../../src/images/designing-data-intensive-apps/bug-without-monotonic-reads.png";
import etlImg from "../../../../src/images/designing-data-intensive-apps/etl.png";
import multiLeaderImg from "../../../../src/images/designing-data-intensive-apps/multi-leader-replication.png";
import oltpVsOlapImg from "../../../../src/images/designing-data-intensive-apps/oltp-vs-olap.png";
import singleLeaderImg from "../../../../src/images/designing-data-intensive-apps/single-leader-replication.png";
import starSchemaImg from "../../../../src/images/designing-data-intensive-apps/star-schema.png";
import * as React from 'react';
export default {
  HashLink,
  InternalLink,
  asyncFollowerImg,
  bTreeImg,
  consistentPrefixImg,
  monotonicReadsImg,
  etlImg,
  multiLeaderImg,
  oltpVsOlapImg,
  singleLeaderImg,
  starSchemaImg,
  React
};