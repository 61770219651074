import React from 'react';
import styled from 'styled-components';

import { colors } from '../styles/base';

const StyledTable = styled.table`
  width: 70%;
  text-align: center;
  margin: 2rem auto;
  border: 3px solid ${colors.LEMON_LIME};

  & th,
  & td {
    border: 1px solid ${colors.LEMON_LIME};
  }
`;

export const Table = ({ data }) => {
  return (
    <StyledTable>
      <thead>
        {data.header.map(value => (
          <th key={value}>{value}</th>
        ))}
      </thead>
      <tbody>
        {data.rows.map((row, index) => (
          <tr key={index}>
            {row.map(value => (
              <td key={value}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};
