import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { colors } from '../styles/base';
import arrow from '../images/north-east-arrow.svg';

export const linkStyles = css`
  color: ${colors.SUN_FLOWER};

  &:hover {
    text-decoration: none;
  }
`;

const A = styled.a`
  ${linkStyles};
  background: ${props =>
    props.hideArrow ? 'inherit' : `url(${arrow}) no-repeat 100%`};
  padding-right: ${props => (props.hideArrow ? 'inherit' : '1.5rem')};

  &:after {
    content: ' - External link';
    position: absolute;
    left: -10000px;
    top: auto;
  }
`;

export const InternalLink = styled(Link)`
  ${linkStyles};
`;

export const ExternalLink = ({ children, ...props }) => (
  <A target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </A>
);

export const HashLink = styled(Link)`
  display: flex;
  color: ${colors.LEMON_LIME};
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;

    &:after {
      display: block;
    }
  }

  &:after {
    content: '🔗';
    display: none;
    margin-left: 0.5rem;
    line-height: 1.2;
  }
`;
