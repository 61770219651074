import { HashLink } from "../../../../src/components/link";
import complexityScaleImg from "../../../../src/images/complexity-scale.png";
import devSpeedGraphImg from "../../../../src/images/dev-speed.png";
import knittedCastleImg from "../../../../src/images/knitted-castle.png";
import composableComponentsImg from "../../../../src/images/composable-components.png";
import modularComponentsImg from "../../../../src/images/modular-components.png";
import * as React from 'react';
export default {
  HashLink,
  complexityScaleImg,
  devSpeedGraphImg,
  knittedCastleImg,
  composableComponentsImg,
  modularComponentsImg,
  React
};